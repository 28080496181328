@tailwind base;
@tailwind components;
@tailwind utilities;

.txt_shadow_one {
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
.middle_point {
  top: 292px;
  width: 160px;
  height: 160px;
}
.disk_top,
.disk_bottom {
  width: 380px;
}
.disk_top {
  top: 120px;
}
.disk_bottom {
  top: 515px;
}
.disk_top .logo {
  width: 265px;
}
.txt_1 {
  text-shadow: 4px 4px 12px #0e0e0d;
  font-size: 36px;
  color: #fff;
  text-shadow: 1px 1px #0b0738, -1px 1px #0b0738, 1px -1px #0b0738,
    -1px -1px #0b0738;
  line-height: 1.4;
}
.disk_wrapper {
  flex: 0 0 780px;
}
.incrementor {
  top: -85px;
  width: 371px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.incrementor button {
  filter: drop-shadow(1px 1px 3px rgba(0, 0, 0, 0.4));
  transition: 0.3s;
}

/* Media Query */
/* Based on Minimum Width */
@media screen and (min-width: 1280px) {
  .disk_wrapper.type-2 {
    flex: 0 0 600px;
  }

  .type-2 .disk_top h4 {
    font-size: 28px;
    line-height: 1.1;
  }
  .type-2 .disk_top h4 span {
    font-size: 28px;
  }
  .type-2 .disk_top {
    top: 95px;
  }
  .type-2 .middle_point {
    top: 221px;
    width: 130px;
    height: 130px;
  }
  .type-2 .disk_bottom {
    top: 403px;
  }
}
@media screen and (min-width: 640px) {
  .me_nu li a svg {
    width: 50px;
    height: 50px;
  }
}
/* Based on Maximum Width */
@media screen and (max-width: 1279px) {
  .disk_wrapper.type-2 {
    width: 430px;
    flex: 0 0 430px;
  }
  .type-2 .middle_point {
    top: 158px;
    width: 95px;
    height: 95px;
  }
  .type-2 .disk_top {
    top: 66px;
  }
  .type-2 .disk_bottom {
    top: 284px;
  }
  .incrementor {
    top: -60px;
    width: 275px;
  }
  .type-2 .disk_top h4 {
    font-size: 22px;
    line-height: 1.2;
  }
}
@media screen and (max-width: 810px) {
  .disk_wrapper.type-2 {
    margin-left: -45px;
  }
}
@media screen and (max-width: 767px) {
  .disk_wrapper.type-2 {
    margin-left: auto;
  }
}
@media screen and (max-width: 639px) {
  .disk_wrapper.type-2 {
    transform: translateX(-46px);
  }
  .disk_wrapper {
    flex: 0 0 430px;
    width: 430px;
  }
  .txt_1 {
    font-size: 23px;
    line-height: 1.2;
  }
  .disk_top .logo {
    width: 160px;
  }
  .disk_top {
    top: 66px;
  }
  .middle_point {
    top: 158px;
    width: 95px;
    height: 95px;
  }
  .disk_bottom {
    top: 272px;
  }
  .me_nu li a svg {
    width: 40px;
    height: 40px;
  }
  .disk-outer > div .disk_wrapper.type-2:nth-child(2) {
    margin-top: -30px;
  }
}
.table-custom tbody tr:not(:last-child) {
  border-bottom: 1px solid #40c5e6;
}
.table-custom td {
  color: #fff;
  height: 45px;
  font-weight: 400;
  padding: 5px 15px;
}
.table-custom th {
  background-color: #f8892b;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  font-family: "Oxanium", "sans-serif";
  height: 45px;
  padding: 5px 15px;
  text-align: left;
}
.table-custom th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.table-custom th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
@media screen and (max-width: 767px) {
  .table-custom-scroller {
    overflow-x: scroll;
  }
}

#google_translate_element {
  position: fixed;
  z-index: 10000;
  top: 90px;
  right: 20px;
  width: 50px;
}
#google_translate_element span {
  display: none;
}
#google_translate_element .goog-te-combo {
  color: #000;
}
#google_translate_element select {
  width:50px;
  height: 40px;
  background-color: transparent;
  outline: none;
  font-size: 0px;
  background-image: url('Assets/translation.png');
  background-size: auto 50px;
  background-repeat: no-repeat;
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}
.goog-logo-link{
  display:none !important;
}
.goog-te-gadget{
  color:transparent!important;
}
.goog-te-banner-frame{
  display:none !important;
}
#goog-gt-tt, .goog-te-balloon-frame
{
display: none !important;
}
.goog-text-highlight 
{ 
background: none !important;
box-shadow: none !important;
}